/* switch-toggle.css */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e0e0e0;
    border: 1px solid #ccc;
    box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.2);
    transition: 0.4s ease;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: 0.4s ease;
  }
  
  input:checked + .slider {
    background-color: #4caf50;
    border: 1px solid #4caf50;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 3px #4caf50;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  
  .slider:hover {
    background-color: #d6d6d6;
  }
  
  input:checked + .slider:hover {
    background-color: #45a049;
  }
  