/* ==== For Statistics Card ==== */

.dashboard-statcard-title{
    font-weight: bold;
    font-size: 20px;
}
  
.dashboard-statcard-text{
    /* width: 100%;  */
    /* display: flex; */
    /* justify-content: space-between; */
    /* align-items: center; */
    margin-top: 30px;
}

.dashboard-statcard-icon{
    /* display: flex; */
    margin-left: 10px;
    margin-bottom: 5px;
    /* align-self:center */
}



/* ==== For Application Card ==== */

.dashboard-appcard-header{
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 14px;
}

.dashboard-appcard-title{
    font-size: 20px;
    /* margin-bottom: 0px; */

}

.dashboard-appcard-icon{
    margin-bottom: 5px;
    margin-right: 5px;
    font-size: 15px;
}

.dashboard-appcard-text{
    margin-bottom: 7px;
}

.dashboard-appcard-status{
    position: relative;
    top: -2px;
    margin-left: 12px;
    margin-right: 15px;
    font-weight: bold;
    color: #ffffff;
    background-color: #f9a825;
}

.dashboard-appcard-date{
    position: relative;
    top: -2px;
    margin-left: 15px;
    margin-right: 10px;
    font-weight: bold;
    color: #ffffff;
    background-color: #2196f3;
}

.dashboard-appcard-date-detail{
    position: relative;
    top: 2px;
}

.card-padding{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.toast-title {
    font-size: 17px;
    margin-right: auto;
}

.toast-right {
    margin-left: auto
}

.toast-body {
    font-size: 17px;
}