.modal-body {
    color: #333;
}

.modal-body .clinic-name {
    /* color: #28a745; */
    font-weight: bold;
}

.modal-body .deactivate {
    color: #ff4d4f;
    font-weight: bold;
}

.modal-body .activate {
    color: #28a745;
    font-weight: bold;
}


.deactivate-icon {
    color: #ff4d4f;
    font-size: 33px;
    margin-right: 6px;
    transition: color 0.3s ease;
}

.deactivate-icon:hover {
    color: #ff7875;
}

.activate-icon {
    color: #28a745;
    font-size: 33px;
    margin-right: 8px;
    transition: color 0.3s ease;
}

.activate-icon:hover {
    color: #218838;
}