.align-items {
    display: flex;
    flex-direction: column;
    /* Or use 'row' if you want them side-by-side */
}

.align-items input {
    margin-bottom: 5px;
    /* Adjust as needed to control spacing */
}

/* Style the dropdown to match the form input */
/* form .form-select  */
tmpp {
    height: calc(1.5em + 0.75rem + 2px); /* Match height of other inputs */
    padding: 0.375rem 0.75rem; /* Match padding of other inputs */
    font-size: 1rem; /* Match font size of other inputs */
    line-height: 1.5; /* Match line height of other inputs */
    color: #495057; /* Match text color */
    background-color: #fff; /* White background like inputs */
    border: 1px solid #ced4da; /* Match border style */
    border-radius: 0.25rem; /* Match border radius */
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%); /* Match shadow */
  }

/* form .form-select:focus */
 tmp{
    color: #495057; /* Keep the text color on focus */
    background-color: #fff; /* Keep white background on focus */
    border-color: #80bdff; /* Match focus border color */
    outline: 0; /* Remove default outline */
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); /* Match focus shadow */
  }