.navbar-nav {
    display: flex;
    align-items: center;
    /* Center items vertically */
    justify-content: flex-end;
    /* Align items to the end of the container */
}

.navbar-nav .nav-item {
    margin-left: 10px;
    /* Adjust spacing between items */
}

.userAvatar {
    position: relative;
    width: 35px;
    /* Adjust size as needed */
    height: 35px;
    /* Adjust size as needed */
    border-radius: 50%;
    /* Makes it round */
    background-color: #07477A;
    /* Background color */
    display: flex;
    justify-content: center;
    align-items: center;
    /* Center items vertically */
}

.userAvatar:hover {
    background-color: #0056b3;
    /* New background color on hover */
}

.initials {
    color: #ffffff;
    /* Text color */
    font-size: 16px;
    /* Adjust font size as needed */
    /* font-weight: bold; */
    /* Adjust font weight as needed */
    text-transform: uppercase;
    /* Converts text to uppercase */
}