/* styles.css */

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #f3f4f6, #e3e6f1); /* Use gradient background */
    padding: 20px;
}

.box {
    width: 350px;
    padding: 30px;
    background-color: white; /* white background */
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1); /* softer shades */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 16px; /* larger rounded corners */
    text-align: center;
    animation: fadeIn 1s ease-in-out; /* Fade in animation effect */
}

.message {
    margin-bottom: 10px;
    font-size: 24px; /* larger font */
    font-weight: bold;
    color: #333; /* dark gray font */
}

.subMessage {
    margin-bottom: 20px;
    font-size: 16px;
    color: #666; /* light gray subtitle */
}

.button {
    width: 180px;
    font-size: 16px; /* Larger button font */
    border-color: #4a90e2;
}