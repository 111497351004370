.success-icon {
    color: #28a745;
    font-size: 33px;
    margin-right: 8px;
    transition: color 0.3s ease;
}

.success-icon:hover {
    color: #218838;
}

.success-icon.red {
    color: #dc3545;
}

.success-icon.blue {
    color: #007bff;
}