.signup-card {
    max-width: 100%;
}

.signup-card-width {
    max-width: 100%;
    padding-left: 120px;
}

form .form-select {
    height: calc(1.5em + 0.75rem + 2px); /* Match Form.Control */
    padding: 0.375rem 0.75rem;          /* Padding adjustment */
    font-size: 1rem;                    /* Font size */
    border-radius: 0.25rem;             /* Rounded corners */
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%);
    border: 1px solid #ced4da;
    width: 100%;
  }
  
  form .form-select:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  form .form-control {
    height: calc(1.5em + 0.75rem + 2px); /* Match height for all controls */
  }
  

